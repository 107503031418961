<template>
  <AppLayout>
    <main>
      <div
        class="
          page-header
          pb-10
          page-header-dark
          bg-gradient-primary-to-secondary
        "
      >
        <div class="container-fluid">
          <div class="page-header-content">
            <h1 class="page-header-title">
              <div class="page-header-icon"><i data-feather="filter"></i></div>
              <span>Voyage Types</span>
            </h1>
            <div class="page-header-subtitle ml-2">
              All Created Voyage Types
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-n10">
        <div class="card mb-4">
          <div class="card-header">
            Voyage Types
            <router-link
              to="/voyageTypes/newvoyageType"
              class="btn btn-primary ml-auto"
              >Create Voyage Type</router-link
            >
          </div>
          <div class="card-body">
            <div v-if="pageLoading && voyageTypes.length == 0">
              <img
                style="width: 30%"
                class="d-block mx-auto"
                src="@/assets/loader.gif"
              />
            </div>
            <div v-else-if="voyageTypes.length == 0">
              <img
                style="width: 30%"
                class="d-block mx-auto"
                src="@/assets/nodata.svg"
              />
              <p class="text-center">
                No data currently available
                <router-link to="/voyageTypes/newvoyageType"
                  >Create New Voyage Type</router-link
                >
                to continue
              </p>
            </div>
            <div v-else class="datatable table-responsive">
              <table
                class="table table-bordered table-hover"
                id="dataTable"
                cellspacing="0"
                width="100%"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Actions</th>
                  </tr>
                </tfoot>
                <tbody>
                  <tr
                    v-for="(voyageType, i) in voyageTypes"
                    :key="voyageType.id"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>{{ voyageType.name }}</td>
                    <td>
                      <router-link
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit Voyage Type"
                        class="btn btn-datatable btn-transparent-dark mr-2"
                        :to="{
                          name: 'EditVoyageType',
                          params: { id: voyageType.id },
                        }"
                      >
                        <i
                          class="bi bi-pencil"
                          style="font-size: 1rem"
                        ></i></router-link
                      ><button
                        data-toggle="tooltip"
                        title="Deactivate"
                        class="btn btn-datatable btn-icon btn-transparent-dark"
                      >
                        <i class="bi bi-x-circle" style="font-size: 1rem"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>


<script>
import AppLayout from "@/layouts/AppLayout.vue";
import { mapState, mapActions } from "pinia";
import { useAdminStore } from "@/stores/admin";

import $ from "jquery";

export default {
  name: "Voyage Types",
  components: { AppLayout },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    ...mapState(useAdminStore, ["pageLoading", "voyageTypes"]),
  },

  methods: {
    ...mapActions(useAdminStore, ["getAllVoyageTypes"]),
  },

  created() {
    this.getAllVoyageTypes().then(() => {
      $(document).ready(function () {
        $("#dataTable").DataTable();
      });
    });
  },
};
</script>